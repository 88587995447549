import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import SEO from 'components/_layout/Seo';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { OfferWithDescription, RelativeSection } from 'sections/Offer/Offer.styled';
import { deepTechData } from 'sections/Offer/offerData';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import styled from 'styled-components';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Deeptech"
        meta={[
          {
            name: 'keywords',
            content:
              'machine learning consulting, codium ai, picture recognition app, microservices benefits, 3d avatars, application scalability, ai cybersecurity solutions, ai strategy consulting, virtual branch, bayesian network, small language models, fraud detection machine learning, neural architecture search, deep belief network, artificial immunity, adaptive ai, how to build an ai model, parameter efficient fine tuning, peft fine tuning, machine learning in marketing, machine learning advertising, react native app development services, react native debugger, latest react version, react-app-rewired, react and react native, react software, angular software, react native reanimated, react native gesture handler, react native ide, react native slider, react native bottom sheet, react-native-web, react animation, react-spring, react-native-screens, react switch, react accordion, react debugger, react modal, react-native-modal, react setstate callback, expo development build, react native for web, react-native-config, react native share, react native flatlist, react native image, jest react, jest npm, ai label generator, python microservices, ai consulting firms, discriminative ai, artificial intelligence consultant, ai in transportation, ai erp, ai in software testing, ai erp chatbot, ai in entertainment',
          },
        ]}
        description={
          "Unlock the potential of your business with our machine learning consulting services. From developing a picture recognition app to implementing fraud detection machine learning, we help organizations leverage AI cybersecurity solutions and enhance their AI strategy consulting. Explore how microservices benefits can improve your application's scalability and efficiency."
        }
      />

      <CommonMargin>
        <RelativeSection title="Deep tech" variant="secondary" id="Deep tech" isMobile={isMd}>
          <HeaderSection>
            Leverage AI (machine learning), blockchain, drones, objects and sounds identification or clean energy tech
            with us as we've developed and use these all.
          </HeaderSection>
        </RelativeSection>
        <Section>
          <Common.Div flex={'column'} gap={isMd ? '0' : '140px'}>
            {deepTechData.map(({ imgSrc, title, desc, descSecondary, href, to }, index) => (
              <OfferWithDescription
                alignLeft={isMd || index % 2 === 0}
                imgSrc={imgSrc}
                title={title}
                desc={desc}
                href={href}
                descSecondary={descSecondary}
                isMd={isMd}
                key={title}
                to={to}
              />
            ))}
          </Common.Div>
        </Section>

        <OurOfferSection />
        <TakeThisStepSection />
        <TrustedBySection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 400;
`;
